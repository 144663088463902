import {
	ApolloClient,
	FetchResult,
	MutationOptions,
	QueryOptions,
	useApolloClient,
} from "@apollo/client";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { useEffect, useMemo, useRef } from "react";

export type ExtractTData<TDoc> =
	TDoc extends TypedDocumentNode<infer TData, infer TVar> ? TData : never;

export type ExtractTVar<TDoc> =
	TDoc extends TypedDocumentNode<infer TData, infer TVar> ? TVar : never;

type AsyncQueryOptions = Omit<QueryOptions, "query">;
export const useAsyncQuery = <TData, TVars>(query: TypedDocumentNode<TData, TVars>) => {
	const apolloClient = useApolloClient() as ApolloClient<object>;
	return useMemo(() => getAsyncQuery(apolloClient, query), [apolloClient, query]);
};
export const getAsyncQuery = <TData, TVars>(
	apolloClient: ApolloClient<object>,
	query: TypedDocumentNode<TData, TVars>,
) => {
	return (
		params: AsyncQueryOptions & (TVars[keyof TVars] extends never ? {} : { variables: TVars }),
	): Promise<FetchResult<TData>> =>
		apolloClient.query({
			...params,
			query: query,
		}) as Promise<FetchResult<TData>>;
};

type AsyncMutationOptions = Omit<MutationOptions, "mutation">;

export const useAsyncMutation = <TData, TVars>(mutation: TypedDocumentNode<TData, TVars>) => {
	const apolloClient = useApolloClient() as ApolloClient<object>;
	return useMemo(() => getAsyncMutation(apolloClient, mutation), [apolloClient, mutation]);
};

export const getAsyncMutation = <TData, TVars>(
	apolloClient: ApolloClient<object>,
	mutation: TypedDocumentNode<TData, TVars>,
) => {
	return (
		params: AsyncMutationOptions &
			(TVars[keyof TVars] extends never
				? {}
				: TVars extends { input: any }
					? { input: TVars["input"] } | { variables: TVars }
					: { variables: TVars }),
	): Promise<FetchResult<TData>> =>
		apolloClient.mutate({
			...params,
			variables: "input" in params ? { input: params.input } : params.variables,
			mutation: mutation,
		});
};

export const useComplexIntervalQuery = <TData, TVars>(
	query: TypedDocumentNode<TData, TVars>,
	params: AsyncQueryOptions & { variables: TVars },
	{
		enabled = true,
		initTimeout = 500,
		maxTimeout = 10000,
		scale = (timeout) => timeout * 2,
	}: {
		enabled?: boolean;
		initTimeout?: number;
		maxTimeout?: number;
		scale?: (timeout: number) => number;
	},
) => {
	const curParams = useRef(params);
	const timeout = useRef(initTimeout);
	const enabledRef = useRef(false);
	const apolloClient = useApolloClient();
	const apolloClientRef = useRef(apolloClient);
	useEffect(() => {
		curParams.current = params;
	}, [params]);
	useEffect(() => {
		enabledRef.current = enabled;
	}, [enabled]);
	useEffect(() => {
		apolloClientRef.current = apolloClient;
	}, [apolloClient]);

	useEffect(() => {
		var timeoutId: undefined | NodeJS.Timeout = undefined;
		const callQuery = () => {
			if (enabled) {
				apolloClientRef.current
					.query({
						query,
						fetchPolicy: "network-only",
						...curParams.current,
					})
					.then(() => {
						timeoutId = setTimeout(callQuery, timeout.current);
						timeout.current = Math.max(maxTimeout, scale(timeout.current));
					});
			}
		};
		if (enabled) {
			timeout.current = initTimeout;
			callQuery();
		}
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [enabled, query, maxTimeout, scale, initTimeout]);
	return true;
};
