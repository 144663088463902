import {
	ForwardedRef,
	forwardRef,
	memo,
	useCallback,
	useImperativeHandle,
	useMemo,
	useRef,
} from "react";
import {
	Platform,
	TextInput as RNTextInput,
	TextInputProps as RNTextInputProps,
	StyleSheet,
} from "react-native";
import useStyle from "src/hooks/useStyle";
import { color } from "src/styles";
import fontFamilies from "src/styles/fontFamilies";
import { basicFont } from "src/styles/fonts";
import { radiusSmall } from "src/styles/radius";
import { LINE_HEIGHT_MULTIPLIER } from "../Text/Text";

export interface TextInputProps extends RNTextInputProps {
	onDebouncedChangeText?: (text: string, extra: {}) => void;
}
export type TextInputHandler = {
	setValue: (text: string) => void;
	focus: () => void;
	blur: () => void;
	clear: () => void;
};

const TextInput = (
	{
		style,
		hitSlop = {
			top: 10,
			bottom: 10,
			left: 10,
			right: 10,
		},
		onChangeText,
		onDebouncedChangeText: onDebouncedChange,
		defaultValue,
		...props
	}: TextInputProps,
	ref: ForwardedRef<TextInputHandler>,
) => {
	const { theme } = useStyle();
	const timeout = useRef<NodeJS.Timeout | null>(null);
	const curText = useRef<string>(defaultValue ?? "");
	const handleChangeText = useCallback(
		(text: string) => {
			onChangeText?.(text);
			if (onDebouncedChange) {
				curText.current = text;
				if (!timeout.current) {
					timeout.current = setTimeout(() => {
						onDebouncedChange(curText.current, {});
						timeout.current = null;
					}, 50);
				}
			}
		},
		[onChangeText, onDebouncedChange],
	);
	const rnRef = useRef<RNTextInput>(null);
	useImperativeHandle(ref, () => ({
		setValue: (text: string) => {
			if (Platform.OS === "web") {
				if (rnRef.current) {
					// @ts-expect-error
					rnRef.current.value = text;
				}
			} else {
				rnRef.current?.setNativeProps({
					text,
				});
			}
		},
		focus: () => {
			rnRef.current?.focus();
		},
		blur: () => {
			rnRef.current?.blur();
		},
		clear: () => {
			rnRef.current?.clear();
		},
	}));

	const fontSize = useMemo(
		() =>
			style ? (StyleSheet.flatten(style).fontSize ?? basicFont.fontSize) : basicFont.fontSize,
		[style],
	);

	return (
		<RNTextInput
			ref={rnRef}
			defaultValue={defaultValue}
			keyboardAppearance={theme === "Light" ? "light" : "dark"}
			placeholderTextColor={color.InputPlaceholder[theme]}
			hitSlop={hitSlop}
			style={[
				{
					fontSize,
					lineHeight: fontSize * LINE_HEIGHT_MULTIPLIER,
					color: color.Text[theme],
					fontFamily: fontFamilies.regular,
					verticalAlign: "middle",
					borderRadius: radiusSmall,
				},
				style,
			]}
			onChangeText={handleChangeText}
			{...props}
		/>
	);
};
export default memo(forwardRef(TextInput));
