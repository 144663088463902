/////////////////////////////////////////////////////////////////////////////////////////
// WARNING: DO NOT EDIT UNLESS YOU ARE IN THE ROOT SHARED FOLDER -- NOT A SUBDIRECTORY //
/////////////////////////////////////////////////////////////////////////////////////////

export const isEmailValid = (email: string) =>
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
		email,
	);

export const GATHERING_NAME_CHARACTER_LIMIT = 40;
export const isGatheringNameValid = (name: string) =>
	name.length <= GATHERING_NAME_CHARACTER_LIMIT && name.length > 0;

export const GATHERING_PASSWORD_CHARACTER_LIMIT = 16;
export const isGatheringPasswordValid = (password: string) =>
	password.length <= GATHERING_PASSWORD_CHARACTER_LIMIT &&
	password.length >= 3 &&
	/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(password);

export const COMMUNITY_NAME_CHARACTER_LIMIT = 40;
export const isCommunityNameValid = (name: string) =>
	name.length <= COMMUNITY_NAME_CHARACTER_LIMIT && name.length > 0;

export const NAME_CHARACTER_LIMIT = 40;
export const isNameInputValid = (name: string) => {
	// Replaces line breaks and tabs
	const sanitizedName = name.trim().replace(/(\r\n|\n|\r|\t)/gm, "");
	return (
		sanitizedName === name && !!name && name.length > 0 && name.length <= NAME_CHARACTER_LIMIT
	);
};

export const INSTAGRAM_USERNAME_CHARACTER_LIMIT = 30;
export const isInstagramUsernameValid = (username: string) =>
	username.length <= INSTAGRAM_USERNAME_CHARACTER_LIMIT &&
	username.length >= 3 &&
	/^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/.test(username);

export const SNAPCHAT_USERNAME_CHARACTER_LIMIT = 15;
export const isSnapchatUsernameValid = (username: string) =>
	username.length <= SNAPCHAT_USERNAME_CHARACTER_LIMIT &&
	username.length >= 3 &&
	/^[a-zA-Z][\w\-.]{1,13}[a-zA-Z0-9]$/.test(username);

// I'm not sure if this is the correct LinkedIn character limit
export const LINKED_IN_USERNAME_CHARACTER_LIMIT = 40;
export const isLinkedInUsernameValid = (username: string) =>
	username.length <= LINKED_IN_USERNAME_CHARACTER_LIMIT &&
	username.length >= 3 &&
	/^[a-zA-Z0-9-]{3,40}$/.test(username);

export const isPaletteColorValid = (color: string) => /^#[0-9A-F]{6}$/i.test(color);
