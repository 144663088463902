import { useCallback, useEffect, useRef } from "react";

const useStabilizedCallback = <T extends Function>(fn: T, deps: any[]): T => {
	const _fn = useCallback(fn, deps);
	const _fnRef = useRef(_fn);
	useEffect(() => {
		_fnRef.current = _fn;
	}, [_fn]);
	const stabilizedFn = useCallback((...args: any) => _fnRef.current(...args), []);
	return stabilizedFn as any;
};
export default useStabilizedCallback;
