import { StateStorage } from "zustand/middleware";
import mmkv from ".";

const zustandMmkvStore: StateStorage = {
	setItem: (name, value) => {
		return mmkv.set(name, value);
	},
	getItem: (name) => {
		const value = mmkv.getString(name);
		return value ?? null;
	},
	removeItem: (name) => {
		return mmkv.delete(name);
	},
};

export default zustandMmkvStore;
