import { AlertArgs } from "src/swsh-native/Popups/alert";
import { PromptArgs } from "src/swsh-native/Popups/prompt";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export type PopupInfo = AlertInfo | PromptInfo;

export type AlertInfo = {
	id: string;
	variant: "Alert";
} & AlertArgs;

export type PromptInfo = {
	id: string;
	variant: "Prompt";
} & PromptArgs;

export type PopupButtonInfo = NonNullable<PopupInfo["buttons"]>[number];

interface PopupStore {
	/**
	 * The current popups in the queue
	 *
	 * Popups are added to the end of the popups array
	 */
	popups: PopupInfo[];
	queuePopup: (popup: PopupInfo) => void;
	removePopup: (id: string) => void;
	clear: () => void;
}

const usePopupStore = create<PopupStore>()(
	subscribeWithSelector((set) => ({
		popups: [],
		queuePopup: (popup) => {
			set((state) => ({ popups: [...state.popups, popup] }));
		},
		removePopup: (id) => {
			set((state) => ({
				popups: state.popups.filter((popup) => popup.id !== id),
			}));
		},
		clear: () =>
			set((state) => ({
				popups: [],
			})),
	})),
);

export default usePopupStore;
