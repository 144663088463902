import { MutableRefObject, useCallback, useRef, useState } from "react";

const useStateRef = <T>(
	initialValue: T | (() => T),
): [T, React.Dispatch<React.SetStateAction<T>>, MutableRefObject<T>] => {
	const [value, setValue] = useState<T>(initialValue);
	const valueRef = useRef<T>(value);
	const newSetValue = useCallback((newValue: React.SetStateAction<T>) => {
		const value = newValue instanceof Function ? newValue(valueRef.current) : newValue;
		valueRef.current = value;
		setValue(value);
	}, []);
	return [value, newSetValue, valueRef];
};

export default useStateRef;
