import { makeVar, ReactiveVar } from "@apollo/client";
import { AppStateStatus } from "react-native";

export const uploadDownloadToastFocusedVar = makeVar(false);

/**
 * Auth variables
 */
export const grantedContactsVar = makeVar<undefined | boolean>(undefined);
export const appStateVar = makeVar<AppStateStatus>("active");
export const gatheringSelectorHasChangeVar = makeVar(true);
// Don't forget to add stuff to clearVariables

export const clearingVariables: { defaultValue: any; reactiveVar: ReactiveVar<any> }[] = [];
