class SeededRandomGenerator {
	private seed: number;
	private stringToNumber = (str: string): number => {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash = (hash << 5) - hash + str.charCodeAt(i);
			hash |= 0; // Convert to 32-bit integer
		}
		return hash;
	};
	constructor(seed: number | string = 0) {
		this.seed = typeof seed === "string" ? this.stringToNumber(seed) : seed;
		// Shuffling the seed
		this.random();
	}
	/**
	 * Linear Congruential Generator (LCG)
	 * @returns a random number between 0 and 1
	 */
	public random(): number {
		const a = 1664525;
		const c = 1013904223;
		const m = 2 ** 32;

		this.seed = (a * this.seed + c) % m;
		return this.seed / m;
	}
}
export default SeededRandomGenerator;
