import getRandomString from "src/helpers/getRandomString";
import usePopupStore from "../stores/usePopupStore";
import { PromptArgs } from "./types";

const prompt = (args: PromptArgs) => {
	usePopupStore.getState().queuePopup({
		id: getRandomString(),
		variant: "Prompt",
		...args,
	});
};
export default prompt;
