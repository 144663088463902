import toast from "src/swsh-native/toast";

const toastError = () => {
	toast({
		preset: "error",
		title: "Something went wrong!",
		message: "Please try again later",
	});
};
export default toastError;
