import { ApolloClient } from "@apollo/client";
import zustandMmkvStore from "src/api/mmkv/zustandMmkvStore";
import { curBackend } from "src/config";
import { Timestamp_S } from "src/shared/types/general";
import type { PushNotification } from "src/shared/types/notification";
import { create } from "zustand";
import { createJSONStorage, persist, subscribeWithSelector } from "zustand/middleware";

export interface GlobalStore {
	isSmsAvailable: boolean;
	isIgShareAvailable: boolean;
	navigationPressedNotification: PushNotification.Notification | undefined;
	loggedIn: boolean;
	userType: string | null;
	clientId: string | null;
	curBackend: typeof curBackend;
	didTryShareable: boolean;
	prevOnValidOpen: Timestamp_S | null;
	didSkipSuggestCreateGathering: boolean;
	clear: () => void;
	apolloClient: ApolloClient<object> | null;
	/**
	 * URL that should be opened once the user is logged in and onboarded
	 *
	 * Parsed, so starts with "/"
	 */
	queuedDeepLink: string | null;
	/**
	 * Native only. Flag for whether `useCallbackRedirect` has been handled. Necessary otherwise `useInitialLoginNavigation` will trigger before `queuedDeepLink` is set
	 */
	didHandleNativeCallbackRedirect: boolean;
	secretFeatureMode: boolean;
	isValidFullUser: () => boolean;
	reviewRequestedAt: Timestamp_S | null;
}
const initialState: Pick<
	GlobalStore,
	| "isSmsAvailable"
	| "isIgShareAvailable"
	| "navigationPressedNotification"
	| "loggedIn"
	| "userType"
	| "clientId"
	| "curBackend"
	| "didTryShareable"
	| "prevOnValidOpen"
	| "didSkipSuggestCreateGathering"
	| "apolloClient"
	| "queuedDeepLink"
	| "didHandleNativeCallbackRedirect"
	| "secretFeatureMode"
	| "reviewRequestedAt"
> = {
	isSmsAvailable: false,
	isIgShareAvailable: false,
	navigationPressedNotification: undefined,
	loggedIn: false,
	userType: null,
	clientId: null,
	curBackend: curBackend,
	didTryShareable: false,
	prevOnValidOpen: null,
	didSkipSuggestCreateGathering: false,
	apolloClient: null,
	queuedDeepLink: null,
	didHandleNativeCallbackRedirect: false,
	secretFeatureMode: false,
	reviewRequestedAt: null,
};

const useGlobalStore = create<GlobalStore>()(
	subscribeWithSelector(
		persist(
			(set, get) => ({
				...initialState,
				clear: () => {
					set({
						...initialState,
					});
				},
				isValidFullUser: () => {
					const { loggedIn, userType, clientId } = get();
					return loggedIn && userType === "Full" && Boolean(clientId);
				},
			}),
			{
				name: "zustand-global-store",
				storage: createJSONStorage(() => zustandMmkvStore),
				partialize: (state) => ({
					isSmsAvailable: state.isSmsAvailable,
					isIgShareAvailable: state.isIgShareAvailable,
					loggedIn: state.loggedIn,
					userType: state.userType,
					clientId: state.clientId,
					curBackend: state.curBackend,
					didTryShareable: state.didTryShareable,
					prevOnValidOpen: state.prevOnValidOpen,
					didSkipSuggestCreateGathering: state.didSkipSuggestCreateGathering,
					secretFeatureMode: state.secretFeatureMode,
					reviewRequestedAt: state.reviewRequestedAt,
				}),
			},
		),
	),
);
export const getGlobalStore = () => useGlobalStore.getState();
export const setGlobalStore = useGlobalStore.setState;

export default useGlobalStore;
