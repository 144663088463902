import { UploadAssetInfo } from "../../types";

const deleteUploadAssetData = async (asset: UploadAssetInfo) => {
	if (asset.uriInfo.image) {
		asset.uriInfo.image.release?.();
	}
	if (asset.uriInfo.video) {
		asset.uriInfo.video.release?.();
	}
};
export default deleteUploadAssetData;
