import { Platform } from "react-native";
import PhotoVariant from "src/api/graphql/__generated__/enums/PhotoVariant";
import zustandMmkvStore from "src/api/mmkv/zustandMmkvStore";
import { getCurTime_S } from "src/shared/helpers/timeHelpers";
import { create } from "zustand";
import { createJSONStorage, persist, subscribeWithSelector } from "zustand/middleware";
import deleteUploadAssetData from "../helpers/deleteUploadAssetData";
import wipeUploadAssetData from "../helpers/wipeUploadAssetData";
import { AssetSelector, DownloadAssetInfo, DownloadAssetInfoCore, UploadAssetInfo } from "../types";

type PushToken = string;
export interface AssetPersist {
	downloadAssetsStore: Record<string, DownloadAssetInfo>;
	pushDownloadAssets: (images: DownloadAssetInfo[]) => void;
	getHasDownloadAssets: () => boolean;
	updateDownloadAsset: (
		image: Pick<DownloadAssetInfo, "assetId"> & Partial<DownloadAssetInfo>,
	) => void;
	deleteDownloadAsset: (asset: string | DownloadAssetInfo) => void;

	uploadAssetsStore: Record<string, UploadAssetInfo>;
	pushUploadAssets: (assets: UploadAssetInfo[]) => void;
	getUploadAssetsReadyForUpload: () => UploadAssetInfo[];
	getUploadAssetsReadyForConfirm: () => UploadAssetInfo[];
	getHasUploadAssets: () => boolean;
	getUploadAsset: (assetId: string) => UploadAssetInfo | undefined;
	updateUploadAsset: (
		asset: Pick<UploadAssetInfo, "localPhotoId"> & Partial<UploadAssetInfo>,
	) => void;
	updateUploadAssets: (
		assets: (Pick<UploadAssetInfo, "localPhotoId"> & Partial<UploadAssetInfo>)[],
	) => void;
	deleteUploadAsset: (asset: string | UploadAssetInfo) => Promise<void>;
	/**
	 * Filter keeps upload assets that satisfy the filter
	 */
	filterUploadAssets: (filter: (asset: UploadAssetInfo) => boolean) => void;

	numPrequeuedUploadAssets: number;
	addNumPrequeuedUploadAssets: (num: number) => void;
	clearNumPrequeuedUploadAssets: () => void;

	/**
	 * Keeps track of which live activity push tokens have been sent to the server
	 */
	syncedLiveActivityLookup: Record<PushToken, boolean>;

	clear: () => Promise<void>;
	clearUploadedAssets: () => Promise<void>;
}

type PartialDownloadAssetInfo = DownloadAssetInfoCore &
	AssetSelector<{
		url: string;
	}>;
const partializeDownloadAsset = (downloadAsset: DownloadAssetInfo): PartialDownloadAssetInfo => {
	const { variant } = downloadAsset;
	const common = {
		assetId: downloadAsset.assetId,
		didFail: downloadAsset.didFail,
		isDownloading: false,
		variant: downloadAsset.variant,
	} satisfies Partial<DownloadAssetInfo>;
	switch (variant) {
		case PhotoVariant.Image:
			return {
				...common,
				variant,
				image: {
					url: downloadAsset.image.url,
				},
			};
		case PhotoVariant.LivePhoto:
			return {
				...common,
				variant,
				image: {
					url: downloadAsset.image.url,
				},
				video: {
					url: downloadAsset.video.url,
				},
			};
		case PhotoVariant.Video:
			return {
				...common,
				variant,
				video: {
					url: downloadAsset.video.url,
				},
			};
	}
};
const partializeDownloadAssets = (downloadAssetsStore: Record<string, DownloadAssetInfo>) => {
	const newRemoteAssets: Record<string, PartialDownloadAssetInfo> = {};
	for (const [key, value] of Object.entries(downloadAssetsStore)) {
		newRemoteAssets[key] = partializeDownloadAsset(value);
	}
	return newRemoteAssets;
};

const partializeUploadAssets = (uploadAssetsStore: Record<string, UploadAssetInfo>) => {
	const newLocalAssets: Record<string, UploadAssetInfo> = {};
	// Web doesn't need to persist anything since all data is lost on close anyways
	if (Platform.OS === "web") {
		return {};
	}
	for (const [key, value] of Object.entries(uploadAssetsStore)) {
		newLocalAssets[key] = {
			localPhotoId: value.localPhotoId,
			stateUpdatedAt: value.stateUpdatedAt,
			uriInfo: value.uriInfo,
			timestamp: value.timestamp,
			uploadState: value.uploadState,
			gatheringId: value.gatheringId,
			fileInfo: null,
			isThumbnail: value.isThumbnail,
			uploadedPhotoInfo: value.uploadedPhotoInfo,
			localSelectionId: value.localSelectionId,
			localSelectionIndex: value.localSelectionIndex,
		};
	}
	return newLocalAssets;
};

const useAssetPersist = create<AssetPersist>()(
	subscribeWithSelector(
		persist(
			(set, get) => ({
				downloadAssetsStore: {},
				uploadAssetsStore: {},
				numPrequeuedUploadAssets: 0,
				pushDownloadAssets: (assets) => {
					set((state) => {
						const downloadAssetsStore = state.downloadAssetsStore;
						for (const asset of assets) {
							downloadAssetsStore[asset.assetId] = asset;
						}
						return {
							downloadAssetsStore: {
								...downloadAssetsStore,
							},
						};
					});
				},
				getHasDownloadAssets: () => {
					const downloadAssets = get().downloadAssetsStore;
					return Object.keys(downloadAssets).length > 0;
				},
				updateDownloadAsset: (asset) => {
					set((state) => {
						const downloadAssets = state.downloadAssetsStore;
						const prev = downloadAssets[asset.assetId];
						if (!prev) return {};
						downloadAssets[asset.assetId] = {
							...prev,
							...asset,
						} as DownloadAssetInfo;
						return {
							downloadAssetsStore: {
								...downloadAssets,
							},
						};
					});
				},
				deleteDownloadAsset: (asset) => {
					set((state) => {
						const downloadAssetsStore = state.downloadAssetsStore;
						if (typeof asset === "string") {
							delete downloadAssetsStore[asset];
						} else {
							delete downloadAssetsStore[asset.assetId];
						}
						return {
							downloadAssetsStore: {
								...downloadAssetsStore,
							},
						};
					});
				},
				pushUploadAssets: (assets) => {
					set((state) => {
						const uploadAssetsStore = state.uploadAssetsStore;
						for (const asset of assets) {
							uploadAssetsStore[asset.localPhotoId] = asset;
						}
						return {
							uploadAssetsStore: {
								...uploadAssetsStore,
							},
						};
					});
				},
				getUploadAssetsReadyForUpload: () => {
					const uploadAssets = get().uploadAssetsStore;
					const cur = getCurTime_S();
					const filteredAssets = Object.values(uploadAssets).filter(
						(asset) =>
							asset.uploadState === "Pending" ||
							(asset.uploadState === "Uploaded" &&
								// Force retrying upload if it's been a while
								cur - asset.stateUpdatedAt > 30) ||
							// If failed, we want to retry after 5 seconds
							(asset.uploadState === "Failed" && cur - asset.stateUpdatedAt > 5) ||
							// Fallback
							cur - asset.stateUpdatedAt > 60,
					);
					// Pending first, then failed, then uploaded
					const sortedAssets = filteredAssets.sort((a, b) => {
						if (a.uploadState === "Pending" && b.uploadState !== "Pending") {
							return -1;
						}
						if (a.uploadState !== "Pending" && b.uploadState === "Pending") {
							return 1;
						}
						if (a.uploadState === "Failed" && b.uploadState !== "Failed") {
							return -1;
						}
						if (a.uploadState !== "Failed" && b.uploadState === "Failed") {
							return 1;
						}
						return 0;
					});
					return sortedAssets;
				},
				getUploadAssetsReadyForConfirm: () => {
					const uploadAssets = get().uploadAssetsStore;
					const cur = getCurTime_S();
					const filteredAssets = Object.values(uploadAssets).filter(
						(asset) =>
							(asset.uploadState === "Uploaded" &&
								// Waiting at least 2 seconds after upload
								cur - asset.stateUpdatedAt > 2) ||
							// Polling
							(asset.uploadState === "Confirming" && cur - asset.stateUpdatedAt > 2),
					);
					return filteredAssets;
				},
				getUploadAsset: (assetId) => {
					return get().uploadAssetsStore[assetId];
				},
				getHasUploadAssets: () => {
					const uploadAssets = get().uploadAssetsStore;
					return Object.keys(uploadAssets).length > 0;
				},
				updateUploadAsset: (asset) => {
					set((state) => {
						const uploadAssets = state.uploadAssetsStore;
						const prev = uploadAssets[asset.localPhotoId];
						if (!prev) return {};
						uploadAssets[asset.localPhotoId] = {
							...prev,
							...asset,
							stateUpdatedAt: getCurTime_S(),
						};
						return {
							uploadAssetsStore: {
								...uploadAssets,
							},
						};
					});
				},
				updateUploadAssets: (assets) => {
					set((state) => {
						const uploadAssets = state.uploadAssetsStore;
						for (const asset of assets) {
							const prev = uploadAssets[asset.localPhotoId];
							if (!prev) continue;
							uploadAssets[asset.localPhotoId] = {
								...prev,
								...asset,
								stateUpdatedAt: getCurTime_S(),
							};
						}
						return {
							uploadAssetsStore: {
								...uploadAssets,
							},
						};
					});
				},
				deleteUploadAsset: async (assetOrId) => {
					const assetId =
						typeof assetOrId === "string" ? assetOrId : assetOrId.localPhotoId;
					const asset = get().uploadAssetsStore[assetId];
					if (asset) {
						await deleteUploadAssetData(asset);
					}
					asset?.uriInfo?.image?.release?.();
					asset?.uriInfo?.video?.release?.();
					set((state) => {
						const uploadAssetsStore = state.uploadAssetsStore;
						delete uploadAssetsStore[assetId];
						return {
							uploadAssetsStore: {
								...uploadAssetsStore,
							},
						};
					});
				},
				filterUploadAssets: (filter) => {
					set((state) => {
						const newUploadAssetsStore: Record<string, UploadAssetInfo> = {};
						for (const [key, value] of Object.entries(state.uploadAssetsStore)) {
							if (filter(value)) {
								newUploadAssetsStore[key] = value;
							}
						}
						return {
							uploadAssetsStore: newUploadAssetsStore,
						};
					});
				},
				addNumPrequeuedUploadAssets: (num) => {
					set((state) => {
						return {
							numPrequeuedUploadAssets: Math.max(
								state.numPrequeuedUploadAssets + num,
								0,
							),
						};
					});
				},
				clearNumPrequeuedUploadAssets: () => {
					set({
						numPrequeuedUploadAssets: 0,
					});
				},
				syncedLiveActivityLookup: {},
				clear: async () => {
					const clear = () => {
						set({
							downloadAssetsStore: {},
							uploadAssetsStore: {},
							numPrequeuedUploadAssets: 0,
							syncedLiveActivityLookup: {},
						});
					};
					await wipeUploadAssetData(clear);
				},
				clearUploadedAssets: async () => {
					const uploadedImages = Object.values(get().uploadAssetsStore).filter(
						(image) =>
							image.uploadState === "Uploaded" ||
							image.uploadState === "Confirming" ||
							image.uploadState === "Processed",
					);
					if (uploadedImages.length === 0) return;
					const batchSize = 10;
					const batches: UploadAssetInfo[][] = [];
					for (let i = 0; i < uploadedImages.length; i += batchSize) {
						batches.push(uploadedImages.slice(i, i + batchSize));
					}
					for (const batch of batches) {
						await Promise.all(batch.map((image) => get().deleteUploadAsset(image)));
					}
				},
			}),
			{
				name: "zustand-image-persist",
				storage: createJSONStorage(() => zustandMmkvStore),
				partialize: (state) =>
					({
						downloadAssetsStore: partializeDownloadAssets(state.downloadAssetsStore),
						uploadAssetsStore: partializeUploadAssets(state.uploadAssetsStore),
					}) satisfies Partial<AssetPersist>,
			},
		),
	),
);

export const getAssetPersist = () => useAssetPersist.getState();

export default useAssetPersist;
