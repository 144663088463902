import getRandomString from "src/helpers/getRandomString";
import usePopupStore from "../stores/usePopupStore";
import { AlertArgs } from "./types";

const alert = (args: AlertArgs) => {
	usePopupStore.getState().queuePopup({
		id: getRandomString(),
		variant: "Alert",
		...args,
	});
};
export default alert;
