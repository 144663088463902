import { AnimatePresence as FramerMotionAnimatePresence } from "framer-motion";
import { ComponentProps, ReactNode } from "react";
import isPlaywright from "src/constants/isPlaywright";

const AnimatePresence = ({
	children,
	...props
}: { children: ReactNode } & ComponentProps<typeof FramerMotionAnimatePresence>) => {
	// Playwright doesn't play well with AnimatePresence, and we don't particularly care about animations anyways
	if (isPlaywright) return children;
	return <FramerMotionAnimatePresence {...props}>{children}</FramerMotionAnimatePresence>;
};
export default AnimatePresence;
